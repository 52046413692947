.navbar {
    height: 75px;
    width: calc(100vw - 60px);
    backdrop-filter: blur(17.5px) saturate(150%);
    -webkit-backdrop-filter: blur(17.5px) saturate(150%);
    background-color: rgba(0, 0, 0, 0.40);
    border-bottom: 1px solid #D96D54;
    transition: border-color 0.3s ease-in-out;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    padding-left: 30px;
    padding-right: 30px;
    align-items: center;
    z-index: 20;
    position: fixed;
}

.navbar:hover {
    border-bottom: 1px solid #FCAD29;
}

.navbar-logo {
    height: 45px;
    width: 45px;
}

.title {
    height: 40px!important;
    width: auto;
    text-decoration: none;
    transform: translate(0%, 7.5%);
}

.explorer-search {
    height: 45px;
    width: 45px;
    flex-grow: 1;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;
    font-family: "AquaGrotesque";
    text-transform: uppercase;
    color: #FFFFFF;
    border-radius: 30px;
    background-color: #000000;
    border: 1px solid #D96D54;
    transition: border-color 0.3s ease-in-out;
}

.explorer-search::placeholder {
    font-family: "AquaGrotesque";
    color: #FFFFFF;
    font-size: 18px;
}

.explorer-search:focus {
    outline: none;
    box-shadow: none;
    border-color: #FCAD29;
    font-size: 18px;
}

.settings-icon {
    height: 45px;
    width: 45px;
}

.title-link {
    color: '#FFFFFF';
    text-decoration: none !important;
}