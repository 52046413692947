.home-background {
    background-image: url(../images/backgrounds/svg/home-background.svg);
    background-size: 100%;
    margin: 0;
    height: 100vh;
}

.home-main {
    padding: 30px;
    position: absolute;
    left: 0px;
    top: 75px;
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 30px 30px;
    grid-template-areas:
        "i1 i1 i1 i1 i1 i1 i1 i1 i1 i2 i2 i2"
        "i1 i1 i1 i1 i1 i1 i1 i1 i1 i2 i2 i2"
        "i1 i1 i1 i1 i1 i1 i1 i1 i1 i2 i2 i2"
        "i1 i1 i1 i1 i1 i1 i1 i1 i1 i2 i2 i2"
        "i3 i3 i4 i4 i4 i4 i4 i4 i4 i2 i2 i2"
        "i3 i3 i4 i4 i4 i4 i4 i4 i4 i2 i2 i2"
        "i3 i3 i4 i4 i4 i4 i4 i4 i4 i2 i2 i2"
        "i3 i3 i4 i4 i4 i4 i4 i4 i4 i2 i2 i2"
        "i3 i3 i4 i4 i4 i4 i4 i4 i4 i2 i2 i2"
        "i3 i3 i4 i4 i4 i4 i4 i4 i4 i2 i2 i2"
        "i3 i3 i4 i4 i4 i4 i4 i4 i4 i2 i2 i2"
        "i3 i3 i4 i4 i4 i4 i4 i4 i4 i2 i2 i2";
    height: calc(100% - 75px - 60px);
    width: calc(100% - 60px);
}

.title-container {
    grid-area: i1;
    width: 100%;
    height: 100%;
}

.page-title {
    position: absolute;
    top: 15px;
    font-size: 72px;
}

.page-subtitle {
    position: absolute;
    top: 115px;
    font-size: 40px;
}

.search-bar-container {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.explorer-search-home {
    height: 45px;
    width: calc(100% - 40px);
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;
    font-family: "AquaGrotesque";
    text-transform: uppercase;
    color: #FFFFFF;
    border-radius: 30px;
    background-color: #000000;
    border: 1px solid #D96D54;
    transition: border-color 0.3s ease-in-out;
}

.explorer-search-home::placeholder {
    font-family: "AquaGrotesque";
    color: #FFFFFF;
    font-size: 18px;
}

.explorer-search-home:focus {
    outline: none;
    box-shadow: none;
    border-color: #FCAD29;
    font-size: 18px;
}

.volume-container {
    grid-area: i2;
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    backdrop-filter: blur(17.5px) saturate(150%);
    -webkit-backdrop-filter: blur(17.5px) saturate(150%);
    background-color: rgba(0, 0, 0, 0.40);
    border: 1px solid #D96D54;
    border-radius: 30px;
    transition: border-color 0.3s ease-in-out;
    padding: 30px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr 1fr;
    gap: 30px 30px;
}

.volume-container:hover {
    border-color: #FCAD29;
}

.tx-volume-graphic {
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
    background-color: #D96D54;
    border-radius: 1000px;
    display: grid;
}

.tx-volume-count {
    text-align: center;
}

.crypto-tx-volumes {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 30px;
}

.crypto-tx-volume-card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    align-items: center;
    height: 100%;
    width: auto;
}

.crypto-tx-volume-card-tx-label {
    position: relative;
    top: 0px;
    font-size: 20px;
}

.crypto-tx-volume-card-logo {
    position: relative;
    left: 0px;
    height: 45px;
    width: 45px;
}

.blockchain-container {
    height: 100%;
    width: 100%;
    grid-area: i3;
    backdrop-filter: blur(17.5px) saturate(150%);
    -webkit-backdrop-filter: blur(17.5px) saturate(150%);
    background-color: rgba(0, 0, 0, 0.40);
    border: 1px solid #D96D54;
    border-radius: 30px;
    transition: border-color 0.3s ease-in-out;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}

.blockchain-container:hover {
    border-color: #FCAD29;
}

.blockchain-container-title {
    flex-grow: 0;
    text-align: center;
}

.blockchain-container-subtitle {
    flex-grow: 0;
    text-align: center;
    font-size: 64px;
}

.blockchain-container-crypto-logo {
    flex-grow: 0;
    height: auto;
    width: calc(100% - 60px);
}

.blocks-container {
    grid-area: i4;
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    backdrop-filter: blur(17.5px) saturate(150%);
    -webkit-backdrop-filter: blur(17.5px) saturate(150%);
    background-color: rgba(0, 0, 0, 0.40);
    border: 1px solid #D96D54;
    border-radius: 30px;
    transition: border-color 0.3s ease-in-out;
    padding: 30px;
}

.blocks-container:hover {
    border-color: #FCAD29;
}

.blocks-table {
    border-collapse: collapse;
    border-spacing: 0 30px;
    width: 100%;
    table-layout: fixed;
}

.blocks-table th {
    border-bottom: 1px solid #D96D54;
    padding-left: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
    padding-top: 30px;
    text-align: left;
    font-weight: normal;
}

.blocks-table td {
    height: 100%;
    vertical-align: middle;
    padding: 10px;
}

.blocks-table tbody {
    height: 100%;
}

.blocks-table-logo {
    max-height: 64px;
    width: auto;
    display: block;
    margin: 0 auto;
}

.blocks-table th.coin-column {
    width: 64px;
    text-align: center;
}

.blocks-table th.amount-column {
    width: 200px;
}