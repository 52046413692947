body {
    margin: 0;
    height: 100vh;
    background-color: #000000;
    background-image: url('');
    background-size: 100%;
}

@font-face {
    font-family: AquaGrotesque;
    src: url(aqua.ttf);
}

.h1 {
    color: #FFFFFF;
    font-family: "AquaGrotesque", sans-serif;
    font-size: 32px;
}

.h2 {
    color: #FFFFFF;
    font-family: "AquaGrotesque", sans-serif;
    font-size: 28px;
}

.h3 {
    color: #FFFFFF;
    font-family: "AquaGrotesque", sans-serif;
    font-size: 24px;
}

.h4 {
    color: #FFFFFF;
    font-family: "AquaGrotesque", sans-serif;
    font-size: 20px;
}

.h5 {
    color: #FFFFFF;
    font-family: "AquaGrotesque", sans-serif;
    font-size: 16px;
}

.h6 {
    color: #FFFFFF;
    font-family: "AquaGrotesque", sans-serif;
    font-size: 12px;
}

.p {
    color: #FFFFFF;
    font-family: "AquaGrotesque", sans-serif;
    font-size: 14px;
}

.text {
    color: #FFFFFF;
    font-family: "AquaGrotesque";
}

.no-highlight {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
}

button {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    color: inherit;
    font: inherit;
    text-align: inherit;
    cursor: pointer;
}

select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    color: inherit;
    font: inherit;
    text-align: inherit;
    cursor: pointer;
}