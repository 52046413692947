.background-image {
    position: fixed;
    height: 100%;
    width: 100%;
    right: -550px;
    bottom: -200px;
}

.main-page {
    padding: 30px;
    position: absolute;
    left: 0px;
    top: 75px;
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 30px 30px;
    grid-template-areas:
        "i11 i11 i1 i1 i1 i1 i1 i1 i1 i1 i2 i2"
        "i11 i11 i1 i1 i1 i1 i1 i1 i1 i1 i2 i2"
        "i11 i11 i1 i1 i1 i1 i1 i1 i1 i1 i2 i2"
        "i11 i11 i1 i1 i1 i1 i1 i1 i1 i1 i2 i2"
        "i5 i5 i6 i6 i7 i7 i8 i8 i9 i9 i3 i3"
        "i5 i5 i6 i6 i7 i7 i8 i8 i9 i9 i3 i3"
        "i5 i5 i10 i10 i10 i10 i10 i10 i10 i10 i3 i3"
        "i5 i5 i10 i10 i10 i10 i10 i10 i10 i10 i3 i3"
        "i5 i5 i10 i10 i10 i10 i10 i10 i10 i10 i4 i4"
        "i5 i5 i10 i10 i10 i10 i10 i10 i10 i10 i4 i4"
        "i5 i5 i10 i10 i10 i10 i10 i10 i10 i10 i4 i4"
        "i5 i5 i10 i10 i10 i10 i10 i10 i10 i10 i4 i4";
    height: calc(100% - 75px - 60px);
    width: calc(100% - 60px);
}

.logo-container {
    grid-area: i11;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}

.title-container {
    position: relative;
    grid-area: i1;
    width: 100%;
    height: 100%;
}

.info-page-crypto-logo {
    height: 100%;
    width: auto;
}

.page-title {
    position: absolute;
    top: 15px;
    font-size: 72px;
}

.page-subtitle {
    position: absolute;
    top: 115px;
    font-size: 40px;
}

.latest-block {
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    padding: 30px;
    backdrop-filter: blur(17.5px) saturate(150%);
    -webkit-backdrop-filter: blur(17.5px) saturate(150%);
    background-color: rgba(0, 0, 0, 0.40);
    border: 1px solid #D96D54;
    border-radius: 30px;
    transition: border-color 0.3s ease-in-out;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}

.latest-block:hover {
    border-color: #FCAD29;
}

.latest-block-title {
    text-align: center;
}

.block-1 {
    grid-area: i2;
}

.block-2 {
    grid-area: i3;
}

.block-3 {
    grid-area: i4;
}

.latest-block-divider {
    border: 1px solid #D96D54;
    width: calc(100% - 60px);
}

.mempool-card {
    grid-area: i5;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(17.5px) saturate(150%);
    -webkit-backdrop-filter: blur(17.5px) saturate(150%);
    background-color: rgba(0, 0, 0, 0.40);
    border: 1px solid #D96D54;
    border-radius: 30px;
    transition: border-color 0.3s ease-in-out;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}

.mempool-card:hover {
    border-color: #FCAD29;
}

.mempool-title {
    flex-grow: 0;
    text-align: center;
}

.info-card {
    height: 100%;
    width: 100%;
    backdrop-filter: blur(17.5px) saturate(150%);
    -webkit-backdrop-filter: blur(17.5px) saturate(150%);
    background-color: rgba(0, 0, 0, 0.40);
    border: 1px solid #D96D54;
    border-radius: 30px;
    transition: border-color 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.info-card:hover {
    border-color: #FCAD29;
}

.info-card-1 {
    grid-area: i6;
}

.info-card-2 {
    grid-area: i7;
}

.info-card-3 {
    grid-area: i8;
}

.info-card-4 {
    grid-area: i9;
}

.info-card-title {
    text-align: center;
}

.info-chart {
    grid-area: i10;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(17.5px) saturate(150%);
    -webkit-backdrop-filter: blur(17.5px) saturate(150%);
    background-color: rgba(0, 0, 0, 0.40);
    border: 1px solid #D96D54;
    border-radius: 30px;
    transition: border-color 0.3s ease-in-out;
}

.info-chart:hover {
    border-color: #FCAD29;
}

.crypto-info-background {
    background-size: 100%;
    margin: 0;
    height: 100vh;
}

.bitcoin-background {
    background-image: url(../images/backgrounds/svg/btc-background.svg);
}

.litecoin-background {
    background-image: url(../images/backgrounds/svg/ltc-background.svg);
}

.kadena-background {
    background-image: url(../images/backgrounds/svg/kda-background.svg);
}

.flux-background {
    background-image: url(../images/backgrounds/svg/flux-background.svg);
}

.meowcoin-background {
    background-image: url(../images/backgrounds/svg/mewc-background.svg);
}

.ethereumclassic-background {
    background-image: url(../images/backgrounds/svg/etc-background.svg);
}

.kaspa-background {
    background-image: url(../images/backgrounds/svg/kas-background.svg);
}

.radiant-background {
    background-image: url(../images/backgrounds/svg/rxd-background.svg);
}

.ergo-background {
    background-image: url(../images/backgrounds/svg/erg-background.svg);
}

.ravencoin-background {
    background-image: url(../images/backgrounds/svg/rvn-background.svg);
}

.siacoin-background {
    background-image: url(../images/backgrounds/svg/sc-background.svg);
}

.vertcoin-background {
    background-image: url(../images/backgrounds/svg/vtc-background.svg);
}