@font-face {
    font-family: AquaGrotesque;
    src: url(aqua.ttf);
}

.blockchains {
    position: fixed;
    top: 90px;
    right: 15px;
    backdrop-filter: blur(17.5px) saturate(150%);
    -webkit-backdrop-filter: blur(17.5px) saturate(150%);
    background-color: rgba(0, 0, 0, 0.40);
    border: 1px solid #D96D54;
    border-radius: 30px;
    height: 350px;
    width: 700px;
    z-index: 25;
    transition: border-color 0.3s ease-in-out;
    padding: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    gap: 15px 15px;
    grid-auto-flow: row;
    grid-template-areas:
        ". . ."
        ". . ."
        ". . ."
        ". . .";
}

.blockchains:hover {
    border: 1px solid #FCAD29;
}

.blockchains-button {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    padding-left: 15px;
    align-items: center;
    height: 100%;
    width: auto;
    border-radius: 15px;
    transition: background-image 0.3s ease-in-out;
}

.crypto-logo {
    height: 45px;
    width: 45px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
}

.blockchains-link {
    text-decoration: none;
}

.blockchains-label {
    font-family: AquaGrotesque;
    color: #FFFFFF;
    font-size: 20px;
    transform: translate(0%, 5%);
}

.blockchains-bitcoin {
    border: 1px solid #F7931A;
}

.blockchains-bitcoin:hover {
    background-image: linear-gradient(90deg, #00000000, #F7931A);
}

.blockchains-litecoin {
    border: 1px solid #345D9D;
}

.blockchains-litecoin:hover {
    background-image: linear-gradient(90deg, #00000000, #345D9D);
}

.blockchains-kadena {
    border: 1px solid #ED098F;
}

.blockchains-kadena:hover {
    background-image: linear-gradient(90deg, #00000000, #ED098F);
}

.blockchains-flux {
    border: 1px solid #2B61D1;
}

.blockchains-flux:hover {
    background-image: linear-gradient(90deg, #00000000, #2B61D1);
}

.blockchains-meowcoin {
    border: 1px solid #BC8312;
}

.blockchains-meowcoin:hover {
    background-image: linear-gradient(90deg, #00000000, #BC8312);
}

.blockchains-ethereumclassic {
    border: 1px solid #0B8311;
}

.blockchains-ethereumclassic:hover {
    background-image: linear-gradient(90deg, #00000000, #0B8311);
}

.blockchains-kaspa {
    border: 1px solid #70C7BA;
}

.blockchains-kaspa:hover {
    background-image: linear-gradient(90deg, #00000000, #70C7BA);
}

.blockchains-radiant {
    border: 1px solid #0a3767;
}

.blockchains-radiant:hover {
    background-image: linear-gradient(90deg, #00000000, #0a3767);
}

.blockchains-ergo {
    border: 1px solid #FF5E18;
}

.blockchains-ergo:hover {
    background-image: linear-gradient(90deg, #00000000, #FF5E18);
}

.blockchains-ravencoin {
    border: 1px solid #F0513A;
}

.blockchains-ravencoin:hover {
    background-image: linear-gradient(90deg, #00000000, #F0513A);
}

.blockchains-siacoin {
    border: 1px solid #20EE82;
}

.blockchains-siacoin:hover {
    background-image: linear-gradient(90deg, #00000000, #20EE82);
}

.blockchains-vertcoin {
    border: 1px solid #048657;
}

.blockchains-vertcoin:hover {
    background-image: linear-gradient(90deg, #00000000, #048657);
}